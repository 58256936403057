// export const API_URL = "http://97.74.85.75:8081/"; // API Base URL
// export const API_USER_URL = "http://97.74.85.75:8082/"; //User service url
// export const API_ACT_URL = "http://97.74.85.75:8083/"; //User Activity API URL
// export const MASTER_API_URL = "http://97.74.85.75:8084/"; // Master Data API Base URL


// export const API_URL = "https://api.eximwatch.com/"; // API Base URL
// export const API_USER_URL = "https://api.eximwatch.com/"; //User service url
// export const API_ACT_URL = "https://api.eximwatch.com/"; //User Activity API URL
// export const MASTER_API_URL = "https://api.eximwatch.com/"; // Master Data API Base URL


// export const API_URL = "http://38.114.123.217:8083/"; // API Base URL
// export const API_USER_URL = "http://38.114.123.217:8082/"; //User service url
// export const API_ACT_URL = "http://38.114.123.217:8085/"; //User Activity API URL
// export const MASTER_API_URL = "http://38.114.123.217:8084/"; // Master Data API Base URL

export const API_URL = "https://api.eximwatch.com/"; // API Base URL
export const API_USER_URL = "https://api.eximwatch.com/"; //User service url
export const API_ACT_URL = "https://api.eximwatch.com/"; //User Activity API URL
export const MASTER_API_URL = "https://api.eximwatch.com/"; // Master Data API Base URL